import {hasPricingOptions, PlaceWithTicketInfo, saleScheduled} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import {Place} from '@wix/ambassador-seating-v1-seating-plan/types'
import {createAsyncAction} from '../../services/redux-toolkit'
import {isChooseSeatMode} from '../../selectors/navigation'
import {getPlace, getPlaceQuantity, getPlaces, getSelectedPlace} from '../../selectors/seating/places'
import {getPlaceTicketPriceText, isPlaceArea, isPlaceReservableAsWhole} from '../../selectors/seating/place'
import {
  setShowPricingOptionsPlace,
  setShowPlaceDescription,
  unselectPlace,
  selectPlace,
} from '../../reducers/seating/mode'
import {isInAccessibilityMode} from '../../selectors/seating/mode'
import {isMobile} from '../../../../../commons/selectors/environment'
import {getFees} from '../../selectors/tax-and-fee'
import {validateDonation, validateTicketLimit} from './validation'

export const updatePlace = createAsyncAction<{places: PlaceWithTicketInfo[]}, {place: PlaceWithTicketInfo}>(
  'UPDATE_PLACE',
  async ({place: oldPlace}, {getState}) => {
    const state = getState()

    return {
      places: getPlaces(state).map(place => {
        if (place.id === oldPlace.id) {
          return oldPlace
        } else {
          return place
        }
      }),
    }
  },
)

interface SetPlaceQuantityArgs {
  placeId: string
  count: number
  origin: string
  validateDonation?: boolean
}

export const setPlaceQuantity = createAsyncAction<{places: PlaceWithTicketInfo[]}, SetPlaceQuantityArgs>(
  'SET_PLACE_QUANTITY',
  async ({placeId, count, validateDonation: shouldValidateDonation = true}, {getState, dispatch, rejectWithValue}) => {
    const state = getState()
    const place = getPlace(getState(), placeId)
    const {ticket, donation} = place
    const chooseSeatMode = isChooseSeatMode(state)

    if (count > 0 && shouldValidateDonation) {
      const valid = await dispatch(validateDonation({placeId, ticket, donation})).unwrap()

      if (!valid) {
        return rejectWithValue({error: 'Invalid donation'})
      }
    }

    if (count > 0 && isPlaceReservableAsWhole(place)) {
      count = place.places.length
    }

    let selectedPricingOptionIds: string[]
    if (hasPricingOptions(place.ticket)) {
      selectedPricingOptionIds = place.pricingOptionId ? new Array(count).fill(place.pricingOptionId) : []
    }

    return {
      places: getPlaces(state).map(place => {
        if (place.id === placeId) {
          return {
            ...place,
            quantity: count,
            donation: count >= 1 ? place.donation : undefined,
            selectedPricingOptionIds,
            timeAddedToBasket: !place.quantity && count ? new Date().getTime() : place.timeAddedToBasket,
          }
        } else {
          return chooseSeatMode ? {...place, quantity: 0} : place
        }
      }),
    }
  },
)

export const addPlaceDonation = createAsyncAction<{places: PlaceWithTicketInfo[]}, {placeId: string; donation: string}>(
  'ADD_PLACE_DONATION',
  async ({placeId, donation}, {getState, extra: {flowAPI}}) => {
    const t: TFunction = flowAPI.translations.t as TFunction
    const state = getState()

    return {
      places: getPlaces(state).map(place => {
        if (place.id === placeId) {
          return {
            ...place,
            donation,
            fees: getFees(state.event, place.ticket, t, donation),
            ticketPrice: getPlaceTicketPriceText(place.ticket, t, donation),
          }
        } else {
          return place
        }
      }),
    }
  },
)

export const clickPlace = createAsyncAction<void, {place?: Place; origin: string}>(
  'CLICK_PLACE',
  async ({place, origin}, {getState, dispatch}) => {
    const state = getState()
    if (!place) {
      return
    }

    const showAccessibilityMode = isInAccessibilityMode(state)
    const mobile = isMobile(state)
    const selectedPlace = getSelectedPlace(state)
    const currentCount = getPlaceQuantity(state, place.id)
    const area = isPlaceArea(place)
    const placeWithInfo = getPlace(state, place.id)

    const valid = await dispatch(validateTicketLimit({place: placeWithInfo})).unwrap()
    if (!valid) {
      return
    }

    const handlePlaceSelectUnselect = () =>
      selectedPlace?.id === place.id ? dispatch(unselectPlace()) : dispatch(selectPlace(place.id))

    const handleAddPlaceToBasket = () => {
      const ticket = placeWithInfo.ticket

      if (saleScheduled(ticket)) {
        return
      }

      if (hasPricingOptions(ticket)) {
        dispatch(setShowPricingOptionsPlace(placeWithInfo))
        return
      }

      dispatch(setShowPlaceDescription(null))

      let count: number
      if (area) {
        count = currentCount + 1
      } else {
        count = currentCount ? 0 : 1
      }

      dispatch(setPlaceQuantity({placeId: place.id, count, origin, validateDonation: showAccessibilityMode}))
    }

    if (!mobile || showAccessibilityMode || currentCount) {
      if (mobile && area) {
        handlePlaceSelectUnselect()
      } else {
        handleAddPlaceToBasket()
      }
    } else {
      handlePlaceSelectUnselect()
    }
  },
)
